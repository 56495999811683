<template>
  <b-card>

    <b-card-title class="font-weight-bolder">
      Demo Page - SignalR
    </b-card-title>

    <b-container>

      <b-row class="mt-2"><h4>CallBell</h4></b-row>
      <b-row>
        <b-button @click="srStart" class="mr-1 mb-1" variant="primary">Start</b-button>
        <b-button @click="srStop" class="mr-1 mb-1" variant="secondary">Stop</b-button>
        <b-button @click="srShowState" class="mr-1 mb-1" variant="secondary">ShowState</b-button>
      </b-row>
    </b-container>

  </b-card>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, onUnmounted } from '@vue/composition-api'
import { watchWithFilter, debounceFilter, useCssVar } from '@vueuse/core'
import common from '@/common'
import { srHub, srHubState, srStart, srStop, srUpdateFilter, srEventMap } from '@/libs/signalr-hub'
import { TagManager } from '@/libs/ez-utils'

export default {
  components: {},
  setup() {
    return {}
  },
  data() {
    return {}
  },
  methods: {

  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
</style>
